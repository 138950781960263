import React, {useEffect} from 'react';
import {lang, meta} from "../helpers/translate";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router";

function HelmetHeader({type}) {
    const params = useParams()

    return (
        <Helmet>
            <title>{meta[type + '_title'][+lang[params?.lang || 'en'] || 3]}</title>
            {/*<link rel='canonical' href={ window.location.href } />*/}
            {/*<meta name="description" content={meta[type+ '_desc'][+lang[params?.lang || 'en'] || 3]}/>*/}
            <meta property="og:title" content={meta[type+ '_title'][+lang[params?.lang || 'en'] || 3]}/>
            <meta property="og:description" content={meta[type+ '_desc'][+lang[params?.lang || 'en'] || 3]} />
            {/*<meta property="og:url" content={window.location.href}/>*/}
            {/*<meta property="telegram:app_name" content={meta[type+ '_title'][+lang[params?.lang || 'en'] || 3]} />*/}
        </Helmet>
    );
}

export default HelmetHeader;
