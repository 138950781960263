import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Scroll from "./component/Scroll";
import Home from "./page/Home";
import Fax from "./page/Fax";
import About from "./page/About";
import Developers from "./page/Developers";
import Integration from "./page/Integration";
import Partners from "./page/Partners";
import Login from "./page/Login";
import Register from "./page/Register";
import Confirm from "./page/Confirm";
import DropPass from "./page/DropPass";
import NotFound from "./page/NotFound";
import {useParams} from "react-router";
import ContactUs from "./page/ContactUs";
import { HelmetProvider } from 'react-helmet-async';
function RedirectService() {
    let { lang, service } = useParams();
    if(lang === 'hy'){
        if(service === 'virtual'){
            service = 'virtualpbx'
        }else if(service === 'local'){
            service = 'localpbx'
        }
    }
    else if(lang === 'ru'){
        if(service === 'virtual'){
            service = 'virtualniats'
        }else if(service === 'local'){
            service = 'localniats'
        }else if(service === 'call-center'){
            service = 'callcenter'
        }
    }
    else if(lang === 'en'){
        if(service === 'virtual'){
            service = 'Internetpbx'
        }else if(service === 'local'){
            service = 'localpbx'
        }else if(service === 'call-center'){
            service = 'callcenter'
        }
    }
    return <Navigate to={`/${lang || 'hy'}/services/${service}`} />;
}

function Redirects({loc}) {
    const { lang } = useParams();
    return <Navigate to={`/${lang || 'hy'}/${loc}`} />;
}
function RedirectDoc({loc}) {
    window.location.href = `https://ats.am/resources/${loc}`
}
function RedirectCrm() {
    const { lang } = useParams();
    if(lang === 'hy'){
        return <Navigate to={`/${lang}/crmintegrations`} />;
    }else{
        return <Integration />;
    }
}
function RedirectPartners() {
    const { lang } = useParams();
    if(lang === 'ru'){
        return <Navigate to={`/${lang}/partneri`} />;
    }else{
        return <Partners />;
    }
}


function RedirectAbout() {
    const { lang } = useParams();
    if(lang === 'ru'){
        return <Navigate to={`/${lang}/onas`} />;
    }else{
        return <About />;

    }
}
function RedirectApi() {
    const { lang } = useParams();
    if(lang === 'ru'){
        return <Navigate to={`/${lang}/apidocumentacia`} />;
    }else{
        return <Developers />;

    }
}
const LanguageRedirect = () => {
    const { lang } = useParams();
    const validLanguages = ['ru','en', 'hy'];
    if (!validLanguages.includes(lang)) {
        return <Navigate to="/hy" replace />;
    }
    return <Home />;
};
function App() {
    useEffect(( ) => {
        if(+localStorage.getItem('atsLang') !== 1){
            document.getElementById('root').classList.add('arm')
        }else{
            document.getElementById('root').classList.add('eng')
        }
    }, [])
    const helmetContext = {};
    return (
        <HelmetProvider context={helmetContext}>
            <BrowserRouter basename="/">
                <Scroll/>
                <Routes>
                    <Route path="/" element={<Navigate to='/hy'/>}/>
                    <Route path="/:lang/" element={<LanguageRedirect />}/>
                    <Route path="/:lang/home"  element={<Navigate to='/:lang'/>} />
                    <Route path="/home"  element={<Navigate to='/hy'/>} />
                    <Route path="/:lang/about" element={<RedirectAbout/>}/>
                    <Route path="/:lang/onas" element={<About/>}/>
                    <Route path="/about" element={<Navigate to='/hy/about'/>}/>
                    <Route path="/:lang/login" element={<Login/>}/>
                    <Route path="/:lang/register" element={<Register/>}/>
                    <Route path="/:lang/crm" element={<RedirectCrm/>}/>
                    <Route path="/:lang/crmintegrations" element={<Integration/>}/>
                    <Route path="/:lang/services/:service" element={<Fax/>}/>
                    <Route path="/:lang/single_service/:service" element={<RedirectService />}/>
                    <Route path="/single_service/:service" element={<RedirectService />}/>
                    <Route path="/:lang/service/:service" element={<RedirectService />}/>
                    <Route path="/service/:service" element={<RedirectService />}/>
                    <Route path="/services/:service" element={<RedirectService />}/>
                    <Route path="/:lang/contacts" element={<ContactUs/>}/>
                    <Route path="/:lang/api_document" element={<RedirectApi/>}/>
                    <Route path="/:lang/apidocumentacia" element={<Developers/>}/>
                    <Route path="/:lang/partner" element={<Redirects loc='partners'/>}/>
                    <Route path="/:lang/partners" element={<RedirectPartners />}/>
                    <Route path="/:lang/partneri" element={<Partners />}/>
                    <Route path="/:lang/registered" element={<Confirm/>}/>
                    <Route path="/:lang/drop" element={<DropPass/>}/>
                    <Route path="/images/ats-arm.pdf" element={<RedirectDoc loc="ats-arm.pdf"/>}/>
                    <Route path="/images/ats-ru.pdf" element={<RedirectDoc loc="ats-ru.pdf"/>}/>
                    <Route path="/images/ats-eng.pdf" element={<RedirectDoc loc="ats-eng.pdf"/>}/>
                    <Route path="/not-found" element={<NotFound/>}/>
                    <Route path="/*" element={<NotFound/>}/>

                </Routes>
            </BrowserRouter>
            <ToastContainer closeOnClick hideProgressBar/>
        </HelmetProvider>
    );
}

export default App;
