import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import Api from "../Api";
import {contactUs, lang, menu, meta} from "../helpers/translate";
import Loader from "../component/Loader";
import Wrapper from "../component/Wrapper";
import {Helmet} from "react-helmet-async";
import Contact from "../component/Contact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

function ContactUs(props) {
    const [plus,setPLus] = useState(null)
    const [about,setAbout] = useState(null)
    const [loader, setLoader] = useState(true)
    const params = useParams()
    useEffect(() => {
        (async () => {
            try{
                const pluses = await Api.getPlusBlock(lang[params?.lang || 'en'] || 1)
                setPLus(pluses.data?.pluses[0].plus_translate[0])
                const aboutD = await Api.getAbout(lang[params?.lang || 'en'] || 1)
                setAbout(aboutD.data.about.home_translate[0])
                setLoader(false)
            }catch (e) {

            }
        })()
    }, [lang,params])


    if(loader){
        return <Loader />
    }else{
        return (
            <Wrapper>
                <Helmet>
                    <title>{contactUs?.title[lang[params?.lang || 'en'] || 1]}</title>
                </Helmet>
                <div className='main_container about'>
                    <h3>
                        {contactUs?.title1[lang[params?.lang || 'en'] || 1]}
                    </h3>
                    <p>
                        {contactUs?.desc[lang[params?.lang || 'en'] || 1]}
                    </p>
                </div>
                {/*<div className="main_container images">*/}
                {/*    {image?.map(i => (*/}
                {/*        <figure key={i.title}>*/}
                {/*            <img src={REACT_APP_API_URL + i.image} alt=""/>*/}
                {/*            <figcaption>{i.title}</figcaption>*/}
                {/*        </figure>*/}
                {/*    ))}*/}
                {/*</div>*/}

                <div className="about_block main_container">
                    <div>
                        <div className="mapswrapper">
                            <iframe
                                width="600"
                                height="600"
                                loading="lazy"
                                allowFullScreen
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Yerevan%20Halabyan%20str.%2065%2F5&zoom=13&maptype=roadmap"
                                style={{ border: 0, position: 'relative', zIndex: 2 }}
                                title="Google Maps"
                            />
                            <a
                                href="https://www.fluxaiimagegenerator.net"
                                style={{
                                    color: 'rgba(0,0,0,0)',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 0,
                                }}
                            >
                                fluxaiimagegenerator.net
                            </a>
                        </div>
                        <div className="d-flex brand_block">
                                <span className="brand_icon">
                                    <a className="d-flex justify-content-center w-100" target="_blank" href="http://fb.com/people/ATSAM-virtual-pbx/61555766517673/">
                                        <FontAwesomeIcon icon={faFacebookF}/>
                                    </a>
                                </span>
                            <span className="brand_icon">
                                    <a className="d-flex justify-content-center w-100" target="_blank" href='https://www.instagram.com/ats.am_/'>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </span>
                            <span className="brand_icon">
                                    <a className="d-flex justify-content-center w-100" target="_blank" href="https://www.linkedin.com/company/ats-am/">
                                        <FontAwesomeIcon icon={faLinkedinIn}/>
                                    </a>
                                </span>
                        </div>
                        <div className="d-flex flex-column download">
                            <a className="inform" href="mailto:info@ats.am">info@ats.am</a>
                            <a className="inform" href="tel:+37444720101">+37444720101</a>
                        </div>
                    </div>
                    <Contact />

                </div>
            </Wrapper>
        );
    }
}

export default ContactUs;
