const {REACT_APP_API_URL} = process.env;


class Utils {
    static calculateDiscount (discounts, scount) {
        const sortedDiscounts = discounts.sort((a, b) => b.sqanak - a.sqanak);
        const discountEntry = sortedDiscounts.find(d => d.sqanak === scount);
        if (discountEntry) {
            return discountEntry.discount;
        }
        const nearestSmallerDiscount = sortedDiscounts.find(d => d.sqanak < scount);
        return nearestSmallerDiscount ? nearestSmallerDiscount.discount : 0;
    };
    static sortParams(apiData) {
        const bodyArray = [];
        const queryArray = [];

        for (const item of apiData) {
            if (item.type === 'body') {
                bodyArray.push(item);
            } else if (item.type === 'query') {
                queryArray.push(item);
            }
        }

        return [bodyArray,queryArray]
    }
    static shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    static sumDiscount(arrayOfObjects) {
        let sum = 0
        arrayOfObjects.map(el => {
            sum += +el.discounted
        })
        return sum
    }
    static lang() {
        let lang = ''
        if(+localStorage.getItem('atsLang') === 1){
            lang = 'en'
        }else if(+localStorage.getItem('atsLang') === 2){
            lang = 'ru'
        }else{
            lang = 'hy'
        }
        return lang
    }
    static ifRout(path){
        let rout = 'home'
        if(path.includes('api')){
            rout = 'api'
        }else if(path.includes('onas') || path.includes('about')){
            rout = 'about'
        }else if(path.includes('partner')){
            rout = 'partner'
        }else if(path.includes('crm')){
            rout = 'crm'
        }else if(path.includes('local')){
            rout = 'local'
        }else if(path.includes('center')){
            rout = 'call'
        }else if(path.includes('virtual') || path.includes('Internetpbx')){
            rout = 'virtual'
        }else if(path.includes('contact')){
            rout = 'contact'
        }
        return rout
    }
}

export default Utils;

